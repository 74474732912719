import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { tap, type Observable } from 'rxjs';
import { deepFreeze } from '../../shared/helpers/deep-freeze/deep-freeze.helper';
import { isString } from '../../shared/helpers/is-string/is-string.helper';
import type { CountryDto } from '../models/countries.dto.models';
import type { Country } from '../models/countries.models';

@Injectable({ providedIn: 'root' })
export class CountriesService {
  readonly #http = inject(HttpClient);

  private static countries: ReadonlyArray<Readonly<Country>> = [];

  private static countryCodeToCountryMap: ReadonlyMap<
    Country['code'],
    Readonly<Country>
  > = new Map();

  /**
   * TODO: TSDoc
   */
  static getAllCountries(): ReadonlyArray<Readonly<Country>> {
    return CountriesService.countries;
  }

  /**
   * TODO: TSDoc
   */
  static isCountryCode(value: unknown): value is Country['code'] {
    return (
      isString(value) && CountriesService.countryCodeToCountryMap.has(value)
    );
  }

  /**
   * TODO: TSDoc
   */
  static getCountryByCode(
    code: Country['code'],
  ): Readonly<Country> | undefined {
    return CountriesService.countryCodeToCountryMap.get(code);
  }

  /**
   * TODO: TSDoc
   */
  initializeCountries(): Observable<CountryDto[]> {
    return this.#getCountries().pipe(
      tap((countries) => {
        CountriesService.countries = deepFreeze(countries);
        CountriesService.countryCodeToCountryMap = new Map(
          CountriesService.countries.map((country) => [country.code, country]),
        );
      }),
    );
  }

  #getCountries(): Observable<CountryDto[]> {
    // TODO: fetch this from BE
    return this.#http.get<CountryDto[]>('assets/shared/countries.json');
  }
}
